
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
const firebaseConfig = {
    apiKey: "AIzaSyCJyOIWyRfyA9DRzXFGwRscu_84TrKtjmg",
    authDomain: "king-7fc5c.firebaseapp.com",
    projectId: "king-7fc5c",
    storageBucket: "king-7fc5c.appspot.com",
    messagingSenderId: "179334619687",
    appId: "1:179334619687:web:403b3f0af2dae96db16402",
    measurementId: "G-ZCXP6JXKCK"
};
const firebaseApp = initializeApp(firebaseConfig)
const db = getFirestore(firebaseApp)
const auth = getAuth(firebaseApp);
export { db, auth };