import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './style.css'
import { db } from '../../services/firebaseConnection'
import {
    getDocs,
    collection,
    orderBy,
    query,
    doc,
    getDoc
} from 'firebase/firestore'
import { Social } from '../../components/Social'
import { FaGithub } from 'react-icons/fa'

export default function Home() {

    const [links, setLinks] = useState([]);
    const [socialLinks, setSocialLinks] = useState({});

    useEffect(() => {
        function loadLinks() {
            const linksRef = collection(db, "links")
            const queryRef = query(linksRef, orderBy("created", "asc"))

            getDocs(queryRef)
                .then((snapshot) => {
                    let lista = [];

                    snapshot.forEach((doc) => {
                        lista.push({
                            id: doc.id,
                            name: doc.data().name,
                            url: doc.data().url,
                            bg: doc.data().bg,
                            color: doc.data().color,
                        })
                    })
                    setLinks(lista);
                })
        }
        loadLinks();
    }, [])
    useEffect(() => {
        function loadSocialLinks() {
            const docRef = doc(db, "social", "link")
            getDoc(docRef)
                .then((snapshot) => {
                    if (snapshot.data() !== undefined) {
                        setSocialLinks({
                            facebook: snapshot.data().facebook,
                            instagram: snapshot.data().instagram,
                            github: snapshot.data().github
                        })
                    }
                })
        }
        loadSocialLinks();
    }, [])

    return (
        <div className="home-container">
            <h1>E o Kiing né vida</h1>
            <span>Veja meus links 😎</span>
            <main className="links">

                {links.map((item) => (
                    <section key={item.id} className="link-area" style={{ backgroundColor: item.bg }}>
                        <a href={item.url} target="blank">
                            <p className="link-text" style={{ color: item.color }}>
                                {item.name}
                            </p>
                        </a>
                    </section>
                ))}

                {links.length !== 0 && Object.keys(socialLinks).length > 0 && (
                    <footer>
                        <Social url={socialLinks?.github}>
                            <FaGithub size={35} color="#fff" />
                        </Social>
                    </footer>
                )}
                <Link className="link-teste" to="/admin">© 2024 BKzinhooo</Link>
            </main>
        </div>
    )
}